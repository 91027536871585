import { getCategoryName } from 'Components/Pattern/Category/_utils/getCategoryName';
import { FindInObjects } from 'Helper/FindInObjects';

import {
    CustomPatternCategoryShape,
    PatternCategoryShape,
    PatternSubCategoryShape,
} from '_types/Pattern/Category';
import { CATEGORY_TYPE_SUBCATEGORY } from '_types/Pattern/Category/categoryType';
import { PatternStateShape } from '../type';
import { languageKey } from '_i18n/constants';

/**
 * Finds alphabetical position for inserting pattern into pattern list
 */
const findCategoryPosition = (
    categories: (PatternCategoryShape | PatternSubCategoryShape)[],
    category: PatternCategoryShape | PatternSubCategoryShape,
): number => {
    const lang = localStorage.getItem(languageKey) ?? 'en';
    let index = 0,
        position = 0;

    while (index < categories.length) {
        const listFieldValue = getCategoryName(categories[index], lang),
            fieldValue = getCategoryName(category, lang);

        if (
            typeof listFieldValue !== 'string' ||
            typeof fieldValue !== 'string'
        ) {
            index++;
            continue;
        }

        const compare = fieldValue.localeCompare(listFieldValue);

        if (compare === -1 || compare === 0) {
            position = index;
            break;
        }

        if (index === categories.length - 1) {
            position = index;
            break;
        }
        index++;
    }
    return position;
};

/**
 * Adds the new category to the given category list
 */
const addToList = (
    category: PatternCategoryShape | PatternSubCategoryShape,
    categories: (PatternCategoryShape | PatternSubCategoryShape)[],
): (PatternCategoryShape | PatternSubCategoryShape)[] => {
    const categoryPosition = findCategoryPosition(categories, category);
    categories.splice(categoryPosition, 0, category);

    return categories;
};

/**
 * Adds PatternCategoryShape | PatternSubCategoryShape to the category tree
 */
const addToTree = (
    category: PatternCategoryShape | PatternSubCategoryShape,
    categories: PatternCategoryShape[],
): PatternCategoryShape[] => {
    if (category.type === CATEGORY_TYPE_SUBCATEGORY) {
        // Since this category is a sub-category, we must first find the parent category
        const parentCategoryResult = FindInObjects.indexAndObjectByIdAndType(
            category.parent_id,
            category.parent_type,
            categories,
        );
        if (!parentCategoryResult) {
            /**
             * If for some reason we can't find the parent category,
             * we just return the unaltered state
             */
            return categories;
        }
        // We then type the found object as PatternCategoryShape, to allow us to update the children
        const parentCategory =
            parentCategoryResult.found as PatternCategoryShape;

        categories[parentCategoryResult.index].children = addToList(
            category,
            parentCategory.children,
        ) as PatternSubCategoryShape[];

        return categories;
    }

    /**
     * If we are here, it means that this is a top level cateory,
     * so then we add it to the list and declare the new list as PatternCategoryShape[]
     */
    return addToList(category, categories) as PatternCategoryShape[];
};

/**
 * Removes the given category from the given category list
 */
const removeFromList = (
    category: PatternCategoryShape | PatternSubCategoryShape,
    categories: (PatternCategoryShape | PatternSubCategoryShape)[],
): (PatternCategoryShape | PatternSubCategoryShape)[] => {
    const index = FindInObjects.indexByIdAndType(
        category.id,
        category.type,
        categories,
    );
    if (index !== null) {
        categories.splice(index, 1);
        return categories;
    }

    return categories;
};

/**
 * Removes PatternCategoryShape | PatternSubCategoryShape from the category tree,
 * and returns the updated tree
 */
const removeFromTree = (
    category: PatternCategoryShape | PatternSubCategoryShape,
    categories: PatternCategoryShape[],
): PatternCategoryShape[] => {
    if (category.type === CATEGORY_TYPE_SUBCATEGORY) {
        const parentCategoryResult = FindInObjects.indexAndObjectByIdAndType(
            category.parent_id,
            category.parent_type,
            categories,
        );
        if (!parentCategoryResult) {
            /**
             * If for some reason we can't find the parent category,
             * we just return the unaltered state
             */
            return categories;
        }
        const parentCategory =
            parentCategoryResult.found as PatternCategoryShape;

        categories[parentCategoryResult.index].children = removeFromList(
            category,
            parentCategory.children,
        ) as PatternSubCategoryShape[];

        return categories;
    }

    return removeFromList(category, categories) as PatternCategoryShape[];
};

/**
 * Helper for reducing the pattern categories.
 * Includes CUD Methods with support for all types of categories
 */
export const PatternCategoryReducerHelper = {
    /**
     * Adds the given category to the redux store.
     * Has support for both top level and sub categories
     */
    add: (
        category: CustomPatternCategoryShape | PatternSubCategoryShape,
        state: PatternStateShape,
    ): PatternStateShape => {
        const { categories } = state;

        return {
            ...state,
            categories: [
                ...(addToTree(category, categories) as PatternCategoryShape[]),
            ],
        };
    },

    /**
     * Updates category in the redux state and returns updated state
     *
     * @param category CustomPatternCategoryShape
     * @param state PatternStateShape
     * @returns PatternStateShape
     */
    update: (
        category: CustomPatternCategoryShape | PatternSubCategoryShape,
        state: PatternStateShape,
    ): PatternStateShape => {
        const { categories } = state;

        return {
            ...state,
            categories: [
                ...(addToTree(
                    category,
                    removeFromTree(category, categories),
                ) as PatternCategoryShape[]),
            ],
        };
    },

    /**
     * Removes category from the redux state and returns updates state
     *
     * @param category PatternCategoryShape
     * @param state PatternStateShape
     * @returns PatternStateShape
     */
    remove: (
        category: PatternCategoryShape | PatternSubCategoryShape,
        state: PatternStateShape,
    ): PatternStateShape => {
        const { categories } = state;

        return {
            ...state,
            categories: [
                ...(removeFromTree(
                    category,
                    categories,
                ) as PatternCategoryShape[]),
            ],
        };
    },
};
