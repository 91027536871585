import { backendUrl } from '_config';
import Cookie from 'js-cookie';

const Csrf = {
    async getCookie(): Promise<Response | string> {
        const token = Cookie.get('XSRF-TOKEN');

        if (token) {
            return Promise.resolve(token);
        }
        const createCsrfCookie = await fetch(
            backendUrl + 'sanctum/csrf-cookie',
            {
                credentials: 'include',
            },
        );
        return createCsrfCookie;
    },
};
export default Csrf;
