import axios from 'axios';
import { handleError } from '_services/Api';
import { backendApi } from '_config';
import { xsrfTokenRequestInterceptor } from '../../../_utils/xsrfInterceptor';

const ApiWithFileUpload = axios.create({
    baseURL: backendApi,
    withCredentials: true,
});

ApiWithFileUpload.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => {
        const responseStatus = error.response?.status;
        // handling error here
        return Promise.reject(handleError(responseStatus, error));
    },
);
ApiWithFileUpload.interceptors.request.use(xsrfTokenRequestInterceptor);

export default ApiWithFileUpload;
