import { FC, ReactNode, useEffect, useState } from 'react';

import { AppVersionInLocalForage } from '_services/LocalForage/AppVersion';
import { useTranslation } from 'react-i18next';
import VersionUpgradeMessages from 'VersionUpgrade/Messages/Messages';
import VersionUpdateModal from 'VersionUpgrade/Modal';

/**
 * Component responsible for storing the current app version in indexDB,
 * and for displaying version upgrade message, when needed
 */
const VersionUpgrade: FC = () => {
    const { i18n } = useTranslation();
    const [upgradeMessage, setUpgradeMessage] = useState<null | ReactNode>(
        null,
    );
    const [currentVersion, setCurrentVersion] = useState<string | null>(null);

    useEffect(() => {
        const checkVersion = async () => {
            try {
                // Force dynamic import of version
                const versionModule = await import('_currentVersion');
                const newVersion = versionModule.version;

                console.log('[Version Debug] Current PWA Version:', newVersion);
                console.log(
                    '[Version Debug] Navigator:',
                    navigator.serviceWorker?.controller?.state,
                );

                // Force service worker update if available
                if (navigator.serviceWorker) {
                    const registration = await navigator.serviceWorker.ready;
                    if (registration.waiting) {
                        registration.waiting.postMessage({
                            type: 'SKIP_WAITING',
                        });
                    }
                }

                setCurrentVersion(newVersion);

                const storedVersion = await AppVersionInLocalForage.get();
                console.log('[Version Debug] Stored Version:', storedVersion);

                if (!storedVersion) {
                    console.log(
                        '[Version Debug] No stored version, setting initial version',
                    );
                    await AppVersionInLocalForage.set(newVersion);
                    return;
                }

                if (versionModule.isNewerVersion(storedVersion, newVersion)) {
                    console.log('[Version Debug] Version upgrade detected:', {
                        storedVersion,
                        currentVersion: newVersion,
                    });
                    await AppVersionInLocalForage.set(newVersion);
                    const thisVersionMessage =
                        VersionUpgradeMessages[newVersion] ?? null;

                    if (thisVersionMessage) {
                        const language = i18n.language as LanguageCode;
                        const message = thisVersionMessage[language] ?? null;

                        if (message) {
                            console.log(
                                '[Version Debug] Showing upgrade message for language:',
                                language,
                            );
                            setUpgradeMessage(message);
                        }
                    }
                }
            } catch (error) {
                console.error('[Version Debug] Error checking version:', error);
            }
        };

        checkVersion();

        // Check version every 10 minutes
        const interval = setInterval(checkVersion, 60000 * 10);

        return () => clearInterval(interval);
    }, [i18n.language]);

    const handleCloseModal = async () => {
        console.log(
            '[Version Debug] Closing modal and updating stored version',
        );
        setUpgradeMessage(null);
        if (currentVersion) {
            await AppVersionInLocalForage.set(currentVersion);
        }
    };

    if (upgradeMessage) {
        return (
            <VersionUpdateModal
                opened={true}
                message={upgradeMessage}
                closeModal={handleCloseModal}
            />
        );
    }
    return <></>;
};

export default VersionUpgrade;
