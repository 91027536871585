import { FC } from 'react';

import loader from '_assets/img/icons/loader/loader.gif';
import './Loader.scss';

interface LoaderProps {
    isFullScreen?: boolean;
    size?: 'large' | 'medium' | 'small';
    text?: string;
}

const Loader: FC<LoaderProps> = ({ isFullScreen = true, size, text }) => {
    let loaderClasses = 'loader';
    if (isFullScreen) {
        loaderClasses += ' full-screen';
    }

    if (!isFullScreen && size) {
        loaderClasses += ` ${size}-screen`;
    }

    return (
        <div className={loaderClasses}>
            <img className="loader-img" src={loader} alt="Loading.." />
            {text && <div className="loader-with_text">{text}</div>}
        </div>
    );
};

export default Loader;
