import * as Sentry from '@sentry/react';
import { backendApi } from '_config';
import { AxiosApi } from '_services/Api';
import RequestLimitter from '_services/Api/_utils/RequestLimitter';
import { AxiosError, type AxiosRequestConfig, type AxiosResponse } from 'axios';
import Csrf from './Csrf';

export type AxiosRequestShape = {
    url: string;
    method?: AxiosRequestConfig['method'];
    body?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    headers?: AxiosRequestConfig['headers'];
};

function ensureValidUrl(url: string): string | undefined {
    let thisUrl: string;
    try {
        thisUrl = new URL(url).toString();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
        /**
         * Url may come in with base or without. For example it may be countries/list,
         * or it may be http(s)://{domain}/some/stuff
         * This logic ensures that backend api is used in the first instance
         */
        thisUrl = backendApi + url;
    }

    try {
        /**
         * After we've ensured baseUrl, we try again URL constructor validation, just in case
         */
        thisUrl = new URL(thisUrl).toString();
    } catch (e) {
        /**
         * If this fail now, we display an error because definetly there's something wrong
         */
        console.error('Invalid url used for api call', { url, e });
        return undefined;
    }

    return thisUrl;
}

export const callWithRequestLimiter = async ({
    url,
    method = 'GET',
    body,
    params,
    headers,
}: AxiosRequestShape): Promise<AxiosResponse> => {
    try {
        /**
         * Setting get jobs and remove jobs globally
         * To remove when unmounting of Social Component
         */
        if (!window?.removeRequestLimitterJobs) {
            window.removeRequestLimitterJobs = RequestLimitter.removeRequests;
        }

        return await RequestLimitter.pushRequest(async () => {
            const csrfCookie = await Csrf.getCookie();
            if (!csrfCookie) {
                return Promise.reject(
                    new Error('Invalid CSRF cookie: ' + String(csrfCookie)),
                );
            }
            const thisUrl = ensureValidUrl(url);
            if (!thisUrl) {
                return Promise.reject(
                    new Error('Invalid URL for call: ' + String(url)),
                );
            }
            return await AxiosApi({
                url: thisUrl,
                method,
                data: body,
                params,
                headers,
            });
        }, `API-${method}-${url}`);
    } catch (e) {
        if (
            e instanceof AxiosError &&
            e.response?.status !== 401 &&
            e.status !== 401 &&
            e.status !== 419 &&
            e.code !== 'ERR_NETWORK'
        ) {
            console.log('Error in API request', {
                url,
                method,
                error: e,
            });
            Sentry.captureException(e);
        }
        throw e;
    }
};

const Api = {
    post: (
        url: string,
        data?: AxiosRequestConfig['data'],
    ): Promise<AxiosResponse> =>
        callWithRequestLimiter({ method: 'POST', url, body: data }),
    get: (
        url: string,
        data?: {
            params: AxiosRequestConfig['params'];
        },
    ): Promise<AxiosResponse> =>
        callWithRequestLimiter({ method: 'GET', url, ...data }),
    put: (
        url: string,
        data?: AxiosRequestConfig['data'],
    ): Promise<AxiosResponse> =>
        callWithRequestLimiter({ method: 'PUT', url, body: data }),
    patch: (
        url: string,
        data?: AxiosRequestConfig['data'],
    ): Promise<AxiosResponse> =>
        callWithRequestLimiter({ method: 'PATCH', url, body: data }),
    delete: (url: string): Promise<AxiosResponse> =>
        callWithRequestLimiter({ url, method: 'DELETE' }),
};

export default Api;
