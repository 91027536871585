import { AxiosResponse } from 'axios';
import Api from '../index';
import { CustomJournalTypeDataShape } from '_types/Journal/Type/Custom';
import {
    NewJournalNoteShape,
    UpdateJournalNoteShape,
} from '_types/Journal/Note';
import { JournalNoteShape, JournalTypeShape } from '_types/Journal';

const path = 'journal/';

const JournalApi = {
    async listTypes(): Promise<AxiosResponse<JournalTypeShape[]>> {
        const route = path + 'type/list';

        return Api.get(route);
    },

    async getSelectableIcons(): Promise<AxiosResponse<SelectableIconShape[]>> {
        const route = path + 'type/selectable-icons';

        return Api.get(route);
    },

    async addType(
        data: CustomJournalTypeDataShape,
    ): Promise<AxiosResponse<JournalTypeShape>> {
        const route = path + 'type/add';

        return Api.post(route, data);
    },

    async updateType(
        data: CustomJournalTypeDataShape,
    ): Promise<AxiosResponse<JournalTypeShape>> {
        const route = path + 'type/update';

        return Api.patch(route, data);
    },

    async deleteType(id: number): Promise<AxiosResponse<void>> {
        const route = path + 'type/delete';

        return Api.delete(route + '?id=' + id);
    },

    async setBulkContent(
        data: (NewJournalNoteShape | UpdateJournalNoteShape)[],
    ): Promise<AxiosResponse<JournalNoteShape[]>> {
        const route = path + 'note/set-bulk-content';

        return Api.post(route, data);
    },
};
export default JournalApi;
