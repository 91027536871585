// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA
import * as Sentry from '@sentry/react';

// Add diagnostic function to check environment
const checkBrowserEnvironment = () => {
    console.log('=== Browser Environment Information ===');
    console.log('User Agent:', navigator.userAgent);
    console.log('Service Worker API Available:', 'serviceWorker' in navigator);

    // Fix for Element content property error
    const cspMetaTag = document.querySelector(
        'meta[http-equiv="Content-Security-Policy"]',
    );
    const cspContent = cspMetaTag
        ? (cspMetaTag as HTMLMetaElement).content
        : 'No CSP meta tag found';
    console.log('CSP:', cspContent);

    // Add to checkBrowserEnvironment function
    console.log('ServiceWorker API Details:', {
        hasServiceWorker: 'serviceWorker' in navigator,
        hasRegister: navigator?.serviceWorker?.register !== undefined,
        isGoNative: navigator.userAgent.includes('gonative'),
        serviceWorkerType: typeof navigator?.serviceWorker,
        registerType: typeof navigator?.serviceWorker?.register,
    });

    // Check storage quota if available
    if ('storage' in navigator && 'estimate' in navigator.storage) {
        navigator.storage
            .estimate()
            .then((estimate) => {
                // Fix for possibly undefined values
                const usage = estimate.usage || 0;
                const quota = estimate.quota || 0;
                console.log(
                    'Storage usage:',
                    usage,
                    'bytes of',
                    quota,
                    'bytes',
                );
                console.log(
                    'Storage percentage used:',
                    (usage / quota) * 100,
                    '%',
                );
            })
            .catch((err) => console.error('Storage estimate error:', err));
    } else {
        console.log('Storage API not available');
    }

    // Check for browser extensions or modifications
    const isModified =
        'serviceWorker' in navigator &&
        Object.getOwnPropertyDescriptor(Navigator.prototype, 'serviceWorker')
            ?.get !== undefined &&
        Object.getOwnPropertyDescriptor(Navigator.prototype, 'serviceWorker')
            ?.configurable === false;

    console.log(
        'Navigator.serviceWorker possibly modified by extension:',
        isModified,
    );

    // Check if register function is modified
    try {
        // Get the toString of the register function
        const registerFnString = navigator.serviceWorker?.register?.toString();
        console.log('Register function signature:', registerFnString);

        if (!registerFnString) {
            console.log('No register function found');
            console.log(
                'navigator.serviceWorker:',
                JSON.stringify(navigator.serviceWorker),
            );
            return;
        }

        // Check for anomalies in the function signature
        const isNativeFunction = registerFnString.includes('[native code]');
        console.log('Is register native function:', isNativeFunction);

        // Detect any suspicious strings in the function definition
        const suspiciousTerms = [
            'wrsParams',
            'Rejected',
            'intercept',
            'extension',
        ];
        const containsSuspicious = suspiciousTerms.some((term) =>
            registerFnString.includes(term),
        );
        console.log(
            'Register function contains suspicious terms:',
            containsSuspicious,
        );

        if (!isNativeFunction || containsSuspicious) {
            console.warn(
                'Service worker register function appears to be modified or intercepted!',
            );
        }
    } catch (error) {
        console.error(
            'Error inspecting service worker register function:',
            error,
        );
    }
};

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === '[::1]' ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(
            /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
        ),
);

type Config = {
    onSuccess?: (registration: ServiceWorkerRegistration) => void;
    onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

/**
 * Attempts to find the correct service worker URL by checking common paths
 */
function findServiceWorkerUrl(): Promise<string> {
    console.log('Attempting to find correct service worker URL');
    const possiblePaths = [
        '/service-worker.js',
        '/static/js/service-worker.js',
        '/sw.js',
        '/workbox-service-worker.js',
    ];

    // Add the publicUrl prefix to all paths
    const publicUrl = process.env.PUBLIC_URL || '';
    const pathsToCheck = possiblePaths.map((path) =>
        path.startsWith('/') ? `${publicUrl}${path}` : `${publicUrl}/${path}`,
    );

    console.log('Checking these possible service worker paths:', pathsToCheck);

    // Check each path with a fetch request
    const fetchPromises = pathsToCheck.map((path) =>
        fetch(path, { cache: 'no-store' })
            .then((response) => ({
                path,
                status: response.status,
                ok: response.ok,
                contentType: response.headers.get('content-type'),
            }))
            .catch(() => ({
                path,
                status: 0,
                ok: false,
                contentType: null,
            })),
    );

    return Promise.all(fetchPromises).then((results) => {
        console.log('Service worker path check results:', results);

        // Find the first successful path
        const validPath = results.find(
            (result) => result.ok && result.contentType?.includes('javascript'),
        );

        if (validPath) {
            console.log('Found valid service worker at:', validPath.path);
            return validPath.path;
        }

        // If no valid path was found, return the default
        console.warn(
            'No valid service worker found at common paths, using default path',
        );
        return `${publicUrl}/service-worker.js`;
    });
}

export function register(config?: Config): void {
    // Add environment check
    checkBrowserEnvironment();

    console.log(
        'Service worker registration starting. Environment:',
        process.env.NODE_ENV,
    );
    console.log('Is serviceWorker in navigator:', 'serviceWorker' in navigator);
    console.log('Is localhost:', isLocalhost);
    console.log('PUBLIC_URL:', process.env.PUBLIC_URL);

    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
        console.log('publicUrl:', publicUrl.toString());
        console.log('window.location.origin:', window.location.origin);
        console.log('Protocol:', window.location.protocol);

        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebook/create-react-app/issues/2374
            console.log(
                'Different origins detected. Service worker registration aborted.',
            );
            return;
        }

        window.addEventListener('load', () => {
            // Instead of hardcoding the service worker URL, find it
            findServiceWorkerUrl()
                .then((swUrl) => {
                    console.log('Service worker URL found:', swUrl);

                    if (isLocalhost) {
                        // This is running on localhost. Let's check if a service worker still exists or not.
                        checkValidServiceWorker(swUrl, config);

                        // Add some additional logging to localhost, pointing developers to the
                        // service worker/PWA documentation.
                        navigator.serviceWorker.ready.then(() => {
                            console.log(
                                'This web app is being served cache-first by a service ' +
                                    'worker. To learn more, visit https://cra.link/PWA',
                            );
                        });
                    } else {
                        // Is not localhost. Just register service worker
                        registerValidSW(swUrl, config);
                    }
                })
                .catch((error) => {
                    console.error('Error finding service worker URL:', error);
                    // Fall back to default path
                    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
                    console.log(
                        'Falling back to default service worker URL:',
                        swUrl,
                    );

                    if (isLocalhost) {
                        checkValidServiceWorker(swUrl, config);
                    } else {
                        registerValidSW(swUrl, config);
                    }
                });
        });
    } else {
        console.log(
            'Service worker registration conditions not met. Not registering service worker.',
            {
                env: process.env.NODE_ENV,
                hasServiceWorker: 'serviceWorker' in navigator,
            },
        );
    }
}

/**
 * Utility function to validate service worker content
 */
function validateServiceWorkerContent(content: string): boolean {
    console.log('Validating service worker content...');

    // Check if the content is empty or too small
    if (!content || content.length < 100) {
        console.error('Service worker content is too small or empty:', content);
        return false;
    }

    // Check for expected workbox imports or service worker patterns
    const hasWorkboxImports =
        content.includes('workbox') ||
        content.includes('precacheAndRoute') ||
        content.includes('clientsClaim');

    if (!hasWorkboxImports) {
        console.warn(
            'Service worker does not contain expected Workbox imports',
        );
    }

    // Check for common service worker event handlers
    const hasInstallEvent =
        content.includes("self.addEventListener('install'") ||
        content.includes("addEventListener('install'") ||
        content.includes('addEventListener("install"');

    const hasActivateEvent =
        content.includes("self.addEventListener('activate'") ||
        content.includes("addEventListener('activate'") ||
        content.includes('addEventListener("activate"');

    const hasFetchEvent =
        content.includes("self.addEventListener('fetch'") ||
        content.includes("addEventListener('fetch'") ||
        content.includes('addEventListener("fetch"');

    if (!hasInstallEvent && !hasActivateEvent && !hasFetchEvent) {
        console.warn('Service worker does not contain expected event handlers');
    }

    // Check if it contains syntax errors
    try {
        // This won't catch all syntax errors, but it will catch some common ones
        // eslint-disable-next-line no-new-func
        new Function(content);
    } catch (error) {
        console.error('Service worker content contains syntax errors:', error);
        return false;
    }

    console.log('Service worker content validation summary:', {
        hasWorkboxImports,
        hasInstallEvent,
        hasActivateEvent,
        hasFetchEvent,
    });

    return true;
}

function registerValidSW(swUrl: string, config?: Config) {
    console.log('Attempting to register service worker at:', swUrl);

    if (!!navigator?.serviceWorker?.register) {
        console.log('ServiceWorker.register function exists');
        fetch(swUrl, {
            // Add cache control to prevent cached results
            headers: {
                'Cache-Control': 'no-cache',
            },
        })
            .then((response) => {
                console.log(
                    'Service worker file fetch status:',
                    response.status,
                    response.statusText,
                );
                console.log('Response headers:', [
                    ...response.headers.entries(),
                ]);

                if (!response.ok) {
                    throw new Error(
                        `Service worker file not accessible: ${response.status}`,
                    );
                }
                return response.text();
            })
            .then((content) => {
                console.log('Service worker file size:', content.length);
                // Log first few characters to verify content
                console.log(
                    'Service worker content start:',
                    content.substring(0, 100) + '...',
                );

                // Validate the service worker content
                const isValidContent = validateServiceWorkerContent(content);
                if (!isValidContent) {
                    throw new Error('Service worker content validation failed');
                }

                // Create a separate try/catch block for registration
                try {
                    // Add to register function before registration attempt
                    console.log('Pre-registration state:', {
                        controller: navigator?.serviceWorker?.controller,
                        ready: navigator?.serviceWorker?.ready,
                    });

                    // Attempt to register after confirming file exists
                    console.log(
                        'About to call navigator.serviceWorker.register...',
                    );
                    const registerPromise =
                        navigator.serviceWorker.register(swUrl);
                    console.log('Register promise created successfully');
                    return registerPromise;
                } catch (registerError) {
                    console.error(
                        'Direct register error (synchronous):',
                        registerError,
                    );
                    Sentry.captureException(registerError, {
                        extra: {
                            phase: 'sync_register',
                            swUrl,
                        },
                    });
                    throw registerError;
                }
            })
            .then((registration) => {
                console.log(
                    'Service worker registration successful:',
                    registration,
                );

                if (registration.waiting) {
                    console.log(
                        'Waiting service worker detected, sending SKIP_WAITING message',
                    );
                    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                }

                registration.onupdatefound = () => {
                    const installingWorker = registration.installing;

                    if (installingWorker == null) {
                        console.error(
                            'installing worker was null. returnining and skipping setting event listners',
                        );
                        return;
                    }

                    if (registration.waiting) {
                        console.log(
                            'Waiting service worker detected in onupdatefound',
                        );
                        registration.waiting.postMessage({
                            type: 'SKIP_WAITING',
                        });
                    }

                    installingWorker.onstatechange = (event) => {
                        const changedWorker = event.target as ServiceWorker;
                        console.log(
                            'Service worker state changed to:',
                            changedWorker.state,
                        );

                        if (changedWorker.state === 'installed') {
                            /**
                             * When a changed worker is installed,
                             * we send SKIP_WAITING to activate it straigt away
                             */
                            console.log(
                                'Service worker installed, sending SKIP_WAITING message',
                            );
                            changedWorker.postMessage({ type: 'SKIP_WAITING' });
                        }

                        if (installingWorker.state === 'activated') {
                            // At this point, everything is already been precached.
                            // It's the perfect time to display a
                            // "Content is cached for offline use." message.
                            console.log('Content is cached for offline use.');

                            // Execute callback
                            if (config && config.onSuccess) {
                                config.onSuccess(registration);
                            }
                            return;
                        }

                        if (!navigator?.serviceWorker?.controller) {
                            /**
                             * There is no controller don't know what's up here but defiently should not continue
                             * @todo Figure out what is up when the serviceWorker controller is null
                             */
                            console.log('No service worker controller found');
                            return;
                        }

                        // At this point, the updated precached content has been fetched,
                        // but the previous service worker will still serve the older
                        // content until all client tabs are closed.
                        console.log(
                            'New content is available and will be used when all ' +
                                'tabs for this page are closed. See https://cra.link/PWA.',
                        );

                        // Execute callback
                        if (config && config.onUpdate) {
                            config.onUpdate(registration);
                        }
                    };
                };

                let refreshing = false;
                /**
                 * Event to auto-reload the page when controller is changed
                 * this will ensure that the latest service worker is in sync with the loaded app files in the browser
                 */
                navigator.serviceWorker.addEventListener(
                    'controllerchange',
                    () => {
                        if (!refreshing) {
                            console.log(
                                'refreshing because of controller change. shoulbe be because of installing new version',
                            );
                            window.location.reload();
                            refreshing = true;
                        }
                    },
                );
            })
            .catch((error) => {
                console.error(
                    'Error during service worker registration:',
                    error,
                );
                // Log more detailed error information
                if (error instanceof TypeError) {
                    console.error('TypeError details:', error.message);
                } else if (error instanceof DOMException) {
                    console.error(
                        'DOMException details:',
                        error.name,
                        error.message,
                    );
                } else if (error instanceof Error) {
                    console.error('Error details:', {
                        message: error.message,
                        name: error.name,
                        stack: error.stack,
                    });
                }
                console.error('Error stack:', error.stack);

                // Try to diagnose specific error causes
                if (error.message.includes('Rejected')) {
                    console.log(
                        'Detected "Rejected" error - this may be caused by:',
                    );
                    console.log(
                        '1. A browser extension interfering with service worker registration',
                    );
                    console.log('2. Content Security Policy restrictions');
                    console.log(
                        '3. Mixed content issues if the page has both HTTP and HTTPS resources',
                    );
                }

                Sentry.captureException(error, {
                    extra: {
                        swUrl,
                        errorType: error.constructor.name,
                    },
                });
            });
    } else {
        console.error('ServiceWorker.register function not available');
    }
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
    console.log('Checking if service worker is valid at:', swUrl);
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl, {
        headers: { 'Service-Worker': 'script' },
    })
        .then((response) => {
            console.log(
                'Service worker fetch response:',
                response.status,
                response.statusText,
            );
            // Ensure service worker exists, and that we really are getting a JS file.
            const contentType = response.headers.get('content-type');
            console.log('Service worker content type:', contentType);

            if (
                response.status === 404 ||
                (contentType != null &&
                    contentType.indexOf('javascript') === -1)
            ) {
                // No service worker found. Probably a different app. Reload the page.
                console.log(
                    'No valid service worker found. Unregistering and reloading.',
                );
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            } else {
                // Service worker found. Proceed as normal.
                console.log(
                    'Valid service worker found. Proceeding with registration.',
                );
                registerValidSW(swUrl, config);
            }
        })
        .catch((error) => {
            console.log(
                'No internet connection found. App is running in offline mode.',
            );
            console.error('Error checking service worker:', error);
        });
}

export function unregister(): void {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                console.log('Unregistering service worker');
                registration.unregister();
            })
            .catch((error) => {
                console.error(error.message);
            });
    }
}
