import { AxiosResponse } from 'axios';
import Api from '_services/Api/index';
import {
    NeedleCategoryShape,
    NeedleShape,
    NeedleUpdateData,
    NewNeedleShape,
} from '_types/Needle';

const baseRoute = 'needle/';

const NeedleApi = {
    /**
     * Fetches the selectable icons availabe on the api and returns a promise with resources
     * @returns Promise<AxiosResponse>
     */
    async getCategories(): Promise<AxiosResponse<NeedleCategoryShape[]>> {
        const route = baseRoute + 'categories';

        return Api.get(route);
    },

    async list(): Promise<AxiosResponse<NeedleShape[]>> {
        const route = baseRoute + 'list';

        return Api.get(route);
    },

    /**
     * Adds
     * @param data NewNeedleShape
     * @returns Promise<AxiosResponse>
     */
    async add(data: NewNeedleShape): Promise<AxiosResponse<NeedleShape>> {
        const route = baseRoute + 'add';

        return Api.put(route, data);
    },

    /**
     * Updates the given needle data in the api
     *
     * @param data NeedleUpdateData
     * @returns Promise<AxiosResponse>
     */
    async update(data: NeedleUpdateData): Promise<AxiosResponse<NeedleShape>> {
        const route = baseRoute + 'update';

        return Api.patch(route, data);
    },

    /**
     * Deletes the needle with the given id from the Repository in the api
     *
     * @param id number
     * @returns Promise<AxiosResponse>
     */
    async delete(id: number): Promise<AxiosResponse<void>> {
        const route = baseRoute + 'delete';

        return Api.delete(route + '?id=' + id.toString());
    },
};

export default NeedleApi;
