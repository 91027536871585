import {
    createSlice,
    createAsyncThunk,
    PayloadAction,
    createSelector,
} from '@reduxjs/toolkit';
import ProjectApi from '_services/Api/Project';
import { ProjectShape, ProjectUpdateDataShape } from '_types/Project';
import { ProjectPictureShape } from '_types/Project/Picture';
import { ProjectRowCounterShape } from '_types/Project/RowCounter';
import { ProjectYarnShape } from '_types/Project/Yarn';
import ProjectPicturesReducerHelper from './ProjectPicturesReducerHelper';
import ProjectReducerHelper from './ProjectReducerHelper';
import {
    OPERATION_TYPE,
    handleRowCounterOperations,
} from './ProjectRowCounterReducerHelper';
import ProjectYarnReducerHelper from './ProjectYarnReducerHelper';
import { DeleteYarnFromProjectPayloadShape, ProjectStateShape } from './type';
import LocalForageProjectData from '_services/LocalForage/ProjectData';
import { PdfRulerShape } from '_types/PdfRuler';
import ProjectPdfRulerHelper from '_services/Redux/Project/ProjectPdfRulerHelper';
import { PatternShape } from '_types/Pattern';
import { RootReduxStateShape } from '_types/Redux';

export type AddProjectPicturesResponseData = {
    thumbnail_url: string;
    pictures_added: ProjectPictureShape[];
};

/**
 * Gets a list of projects from the api, then sets it in the Redux Store
 * @returns void
 */
export const getProjects = createAsyncThunk(
    'projects/getProjects',
    async () => {
        let projectList: ProjectShape[] = [];

        try {
            const response = await ProjectApi.list();
            projectList = response?.data;
            const localForageProjectData =
                await LocalForageProjectData.getList();

            if (
                localForageProjectData &&
                Object.keys(localForageProjectData).length > 0
            ) {
                projectList = projectList.map((project) => {
                    const projectId = project.id;
                    if (
                        localForageProjectData &&
                        localForageProjectData[projectId]
                    ) {
                        project = {
                            ...project,
                            ...localForageProjectData[projectId],
                        };
                    }
                    return project;
                });
            }

            return projectList;
        } catch (error) {
            console.error('Get project list failed: ', error);

            return projectList;
        }
    },
);

const initialState: ProjectStateShape = {
    list: null,
    isLoading: true,
};

export const projectSlice = createSlice({
    name: 'projectSlice',
    initialState,
    reducers: {
        /**
         * Adds a project to the api then to the Redux Store
         * @param data ProjectShape
         * @returns void
         */
        addProject: (
            state: ProjectStateShape,
            action: PayloadAction<ProjectShape>,
        ) => {
            state = ProjectReducerHelper.add(action.payload, state);
        },
        /**
         * Update project to the api then to the Redux Store
         * @param data ProjectShape
         * @returns void
         */
        updateProject: (
            state: ProjectStateShape,
            action: PayloadAction<ProjectUpdateDataShape>,
        ) => {
            state = ProjectReducerHelper.update(action.payload, state);
        },
        /**
         * Update project pattern to the api then to the Redux Store
         * @param data ProjectShape
         * @returns void
         */
        updateProjectsPattern: (
            state: ProjectStateShape,
            action: PayloadAction<ProjectUpdateDataShape['pattern']>,
        ) => {
            state = ProjectReducerHelper.updatePattern(
                action.payload as PatternShape,
                state,
            );
        },
        /**
         * Removes project from redux state
         * @param number
         * @returns void
         */
        removeProject: (state, action: PayloadAction<number>) => {
            state = ProjectReducerHelper.remove(action.payload, state);
        },
        /**
         * Adds yarn to project (finds project from yarn.project_id)
         * @param yarn YarnShape
         * @returns void
         */
        addYarnToProject: (state, action: PayloadAction<ProjectYarnShape>) => {
            state = ProjectYarnReducerHelper.add(action.payload, state);
        },
        /**
         * Updates yarn in project (finds project from yarn.project_id)
         * @param yarn YarnShape
         * @returns void
         */
        updateYarnInProject: (
            state,
            action: PayloadAction<ProjectYarnShape>,
        ) => {
            state = ProjectYarnReducerHelper.update(action.payload, state);
        },
        deleteYarnFromProject: (
            state,
            action: PayloadAction<DeleteYarnFromProjectPayloadShape>,
        ) => {
            state = ProjectYarnReducerHelper.delete(action.payload, state);
        },
        /**
         * Add pictures to project in the redux state
         * @param projectId number
         * @param pictures addProjectPicturesResponseData[]
         * @returns void
         */
        addProjectPictures: (
            state,
            action: PayloadAction<{
                projectId: number;
                data: AddProjectPicturesResponseData;
            }>,
        ) => {
            state = ProjectPicturesReducerHelper.addPictures(
                {
                    projectId: action.payload.projectId,
                    mainThumbnailUrl: action.payload.data.thumbnail_url,
                    pictures: action.payload.data.pictures_added,
                },
                state,
            );
        },
        /**
         * Remove picture from given project in the redux state
         * @param projectId number
         * @param pictureId number
         * @returns void
         */
        removeProjectPicture: (
            state,
            action: PayloadAction<{
                projectId: number;
                pictureId: number;
            }>,
        ) => {
            state = ProjectPicturesReducerHelper.removePicture(
                {
                    projectId: action.payload.projectId,
                    pictureId: action.payload.pictureId,
                },
                state,
            );
        },
        updateMainProjectPicture: (
            state,
            action: PayloadAction<{
                mainPictureId: number;
                mainThumbnailUrl: string;
                projectId: number;
            }>,
        ) => {
            state = ProjectPicturesReducerHelper.updateMainPicture(
                {
                    projectId: action.payload.projectId,
                    mainPictureId: action.payload.mainPictureId,
                    mainThumbnailUrl: action.payload.mainThumbnailUrl,
                },
                state,
            );
        },
        /**
         * Add row counter to project in the redux state
         * @param data rowCounterResponseData
         * @returns void
         */
        addProjectRowCounter: (
            state,
            action: PayloadAction<ProjectRowCounterShape>,
        ) => {
            state = handleRowCounterOperations(
                action.payload,
                state,
                OPERATION_TYPE.ADD_OR_UPDATE,
            );
        },
        /**
         * Remove row counter from given project in the redux state
         * @param id number
         * @returns void
         */
        removeProjectRowCounter: (
            state,
            action: PayloadAction<ProjectRowCounterShape>,
        ) => {
            state = handleRowCounterOperations(
                action.payload,
                state,
                OPERATION_TYPE.REMOVE,
            );
        },
        updateProjectRowCounter: (
            state,
            action: PayloadAction<ProjectRowCounterShape>,
        ) => {
            state = handleRowCounterOperations(
                action.payload,
                state,
                OPERATION_TYPE.ADD_OR_UPDATE,
            );
        },
        updatePdfRulerOnOff: (state, action: PayloadAction<PdfRulerShape>) => {
            state = ProjectPdfRulerHelper(action.payload, state);
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getProjects.pending, (state) => {
                state.isLoading = true; // Set loading to true when the request starts
            })
            .addCase(getProjects.fulfilled, (state, action) => {
                state.list = action.payload;
                state.isLoading = false; // Set loading to false when the request fails
            })
            .addCase(getProjects.rejected, (state) => {
                state.isLoading = false; // Set loading to false when the request fails
            });
    },
});

export const {
    updateProject,
    removeProject,
    addProject,
    updatePdfRulerOnOff,
    updateProjectsPattern,
} = projectSlice.actions;

const ProjectReducer = projectSlice.reducer;

export default ProjectReducer;

/**
 * Created projectListSelector to memorize
 * state and prevent unnecessary re-run on
 * every dispatch action
 * @param state {RootReduxStateShape}
 * @param status {ProjectStatusShape}
 * @returns ProjectShape[]
 */
export const projectListSelector = createSelector(
    [
        (state: RootReduxStateShape) => state.project,
        (_state: RootReduxStateShape, status?: ProjectStatusShape) => status,
    ],
    (project, status) => {
        if (!project.list?.length) {
            return [];
        }

        /**
         * Selecting projects with given status,
         * if a status is given
         */
        if (status) {
            return project.list.filter((project) => project.status === status);
        }

        return project.list;
    },
);
