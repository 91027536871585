import { createApi } from '@reduxjs/toolkit/query/react';
import RequestLimitter from '_services/Api/_utils/RequestLimitter';
import { callWithRequestLimiter as baseQuery } from '../Api';

declare global {
    interface Window {
        removeRequestLimitterJobs: (typeof RequestLimitter)['removeRequests'];
    }
}

// initialize an empty api service that we'll inject endpoints into later as needed
const BaseApi = createApi({
    baseQuery,
    reducerPath: 'knitandnote/reducer',
    endpoints: () => ({}),
});

export default BaseApi;
