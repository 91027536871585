/**
 * Intercept requests and enable XSRF-TOKEN handling in axios if the request is an "unsafe method" and the SLD and
 * TLD of the current page url match the SLD and TLD of the request url.
 */
export const xsrfTokenRequestInterceptor = (request) => {
    try {
        const method = request.method?.toLowerCase();

        const methodRequiresCSRFProtection =
            method !== undefined &&
            ['post', 'put', 'delete', 'patch'].includes(method);

        const parsedCurrentURL = new URL(window.location?.href);
        const parsedTargetURL = new URL(request.baseURL);

        const currentHostname = parsedCurrentURL.hostname
            .split('.')
            .slice(-2)
            .join('.');
        const targetHostname = parsedTargetURL.hostname
            .split('.')
            .slice(-2)
            .join('.');
        const baseDomainsMatch = currentHostname === targetHostname;

        // If settings are explicitly passed, use them, otherwise apply logic as determined by domain match
        if (request.withXSRFToken === undefined) {
            request.withXSRFToken =
                baseDomainsMatch && methodRequiresCSRFProtection;
        }

        if (request.withCredentials === undefined) {
            request.withCredentials = baseDomainsMatch;
        }
    } catch (error) {
        console.error(
            'Skipping XSRF-TOKEN header logic in interceptor: ',
            error,
        );
    }

    return request;
};
