import { AxiosResponse } from 'axios';
import Api from '_services/Api/index';
import {
    NewYarnTypeShape,
    YarnColorShape,
    YarnTypeShape,
} from '_types/YarnStock';
import ApiWithFileUpload from '_services/Api/WithFileUpload';

const baseRoute = 'yarn-stock/';

export type YarnTypeUpdateDataShape = {
    id: number;
    brand?: string | null;
    type?: string | null;
    weight_per_skein?: number;
    length_per_skein?: number;
    gauge?: string | null;
    picture?: File | null;
    picture_path?: string | null;
    weight_per_skein_unit: WeightUnitShape;
    length_per_skein_unit: LengthUnitShape;
};

export type YarnColorUpdateDataShape = {
    id: number;
    color?: string;
    code?: string | null;
    weight?: number;
    picture?: File | null;
};

const YarnStockApi = {
    /**
     * Fetches the selectable icons available on the api and returns a promise with resources
     *
     * @returns Promise<AxiosResponse>
     */
    async list(): Promise<AxiosResponse<YarnTypeShape[]>> {
        const route = baseRoute + 'list-mine';

        return Api.get(route);
    },

    /**
     * Adds new yarn type in the api
     *
     * @param {NewYarnTypeShape} data
     * @returns Promise<AxiosResponse>
     */
    async addType(
        data: NewYarnTypeShape,
    ): Promise<AxiosResponse<YarnTypeShape>> {
        const route = baseRoute + 'add-type';

        return Api.put(route, data);
    },

    /**
     * Adds new yarn color in the api
     *
     * @param {NewYarnColorShape} data
     * @returns {Promise<AxiosResponse>}
     */
    async addColor(data: FormData): Promise<AxiosResponse<YarnColorShape>> {
        const route = baseRoute + 'add-color';
        return ApiWithFileUpload.post(route, data);
    },

    /**
     * Updates yarn type in the api
     *
     * @param {YarnTypeUpdateDataShape} data
     * @returns {Promise<AxiosResponse>}
     */
    async updateType(data: FormData): Promise<AxiosResponse<YarnTypeShape>> {
        const route = baseRoute + 'update-type';

        return ApiWithFileUpload.post(route, data);
    },

    /**
     * Updates yarn color in the api
     *
     * @param {YarnColorUpdateDataShape} data
     * @returns Promise<AxiosResponse>
     */
    async updateColor(data: FormData): Promise<AxiosResponse<YarnColorShape>> {
        const route = baseRoute + 'update-color';

        return ApiWithFileUpload.post(route, data);
    },

    /**
     * Updates yarn type in the api
     *
     * @param {number} data
     * @returns Promise<AxiosResponse>
     */
    async deleteType(id: number): Promise<AxiosResponse<void>> {
        const route = baseRoute + 'delete-type';

        return Api.delete(route + '?id=' + id);
    },

    /**
     * Updates yarn color in the api
     *
     * @param {number} data
     * @returns Promise<AxiosResponse>
     */
    async deleteColor(id: number): Promise<AxiosResponse<void>> {
        const route = baseRoute + 'delete-color';

        return Api.delete(route + '?id=' + id);
    },
};

export default YarnStockApi;
