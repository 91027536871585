import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';
import reducer, { middleware } from './rootReducer';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
});

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            ...middleware,
        ),
    enhancers: (getDefaultEnhancers) =>
        getDefaultEnhancers().concat(sentryReduxEnhancer),
});

export type ThisAppDispatch = typeof store.dispatch;

export const { dispatch } = store;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppDispatch = () => useDispatch<ThisAppDispatch>();
export default store;
