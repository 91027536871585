import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { LocalForageLanguage } from '_services/LocalForage/Language';
import LanguageDetector from 'i18next-browser-languagedetector';
import noTranslations from './no/translations';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const resources = {
    no: {
        translation: noTranslations,
    },
};

i18n.on('languageChanged', function (lng) {
    LocalForageLanguage.get().then((language) => {
        const typedLng = lng as LanguageCode;
        if (language !== lng) {
            LocalForageLanguage.set(typedLng);
        }
    });
});

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    //.use(HttpApi)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        fallbackLng: 'en',
        debug: false, //process.env.NODE_ENV === 'development',
        supportedLngs: ['en', 'no'],
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
