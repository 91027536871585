import { combineReducers } from '@reduxjs/toolkit';

import UserReducer from './User/UserSlice';
import PatternReducer from './Pattern/PatternSlice';
import ProjectReducer from './Project/ProjectSlice';
import FlashMessageReducer from './FlashMessage/FlashMessageSlice';
import JournalReducer from './Journal/JournalSlice';
import CalendarReducer from './Journal/Calendar/CalendarSlice';
import NeedleReducer from './Needle/NeedleSlice';
import YarnStockReducer from './YarnStock/YarnStockSlice';
import GuideStepperReducer from './GuideStepper/GuideStepperSlice';
import HighlightColorReducer from './HighlightColor/HighlightColorSlice';
import HighlightSelectorReducer from './HighlightSelector/HighlightSelectorSlice';
import InterestReducer from '_services/Redux/Interest/InterestSlice';
import { SocialApi } from '_services/Api/Social/SocialApi';
import { thunk } from 'redux-thunk';
import { LayoutReducer } from './Layout/LayoutSlice';
import { OverviewStatsApi } from '_services/Api/OverviewStats/OverviewStatsApi';
import KnitandnoteWordpressApi from '_services/Api/Wordpress/Knitandnote';
import MagentoGraphQl from '_services/Api/Magento/Knitandnote';
import { HighlighterColorApi } from '_services/Api/HighlighterColor/HighlighterColorApi';
import { IPLocationApi } from '_services/Api/IPLocation/IPLocationApi';
import { PatternCategorySelectableIconApi } from '_services/Api/Pattern/Category/SelectableIcon/PatternCategorySelectableIconApi';
import ToastReducer from './Toast/ToastSlice';
import { RecentlyViewedApi } from '_services/Api/Library/RecentlyViewed/RecentlyViewedApi';
import { VideoTutorialCategoryApi } from '_services/Api/VideoTutorial/Category/VideoTutorialCategoryApi';
import { VideoTutorialVideoApi } from '_services/Api/VideoTutorial/Video/VideoTutorialVideoApi';

const rootReducer = combineReducers({
    user: UserReducer,
    pattern: PatternReducer,
    project: ProjectReducer,
    journal: JournalReducer,
    calendar: CalendarReducer,
    yarn_stock: YarnStockReducer,
    needle: NeedleReducer,
    flash: FlashMessageReducer,
    newUserGuide: GuideStepperReducer,
    highlightColor: HighlightColorReducer,
    highlight: HighlightSelectorReducer,
    interest: InterestReducer,
    layout: LayoutReducer,
    toast: ToastReducer,
    // with RTK queries
    [SocialApi.reducerPath]: SocialApi.reducer,
    [OverviewStatsApi.reducerPath]: OverviewStatsApi.reducer,
    [KnitandnoteWordpressApi.reducerPath]: KnitandnoteWordpressApi.reducer,
    [MagentoGraphQl.reducerPath]: MagentoGraphQl.reducer,
    [HighlighterColorApi.reducerPath]: HighlighterColorApi.reducer,
    [IPLocationApi.reducerPath]: IPLocationApi.reducer,
    [RecentlyViewedApi.reducerPath]: RecentlyViewedApi.reducer,
    [PatternCategorySelectableIconApi.reducerPath]:
        PatternCategorySelectableIconApi.reducer,
    [VideoTutorialCategoryApi.reducerPath]: VideoTutorialCategoryApi.reducer,
    [VideoTutorialVideoApi.reducerPath]: VideoTutorialVideoApi.reducer,
});

export const middleware = [
    thunk,
    SocialApi.middleware,
    OverviewStatsApi.middleware,
    KnitandnoteWordpressApi.middleware,
    MagentoGraphQl.middleware,
    HighlighterColorApi.middleware,
    IPLocationApi.middleware,
    RecentlyViewedApi.middleware,
    PatternCategorySelectableIconApi.middleware,
    VideoTutorialCategoryApi.middleware,
    VideoTutorialVideoApi.middleware,
];

export default rootReducer;
