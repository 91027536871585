import * as Sentry from '@sentry/react';
import {
    tooManyRequestsStatusCodes,
    unauthorisedStatusCodes,
    validationErrorStatusCodes,
} from '_constants/statusCodes';
import { version } from '_currentVersion';

const envType = process.env.REACT_APP_ENVIRONMENT ?? undefined;

export const sentryUrl = process.env.REACT_APP_SENTRY_DNS ?? null;

const SentryService = {
    init: (): void => {
        if (!sentryUrl) {
            return;
        }
        const appVersion = version
            ? 'kan-reactjs-pwa@' + version
            : 'no-version';
        Sentry.init({
            dsn: sentryUrl,
            integrations: [
                Sentry.browserTracingIntegration({}),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
                Sentry.captureConsoleIntegration({ levels: ['error'] }),
            ],
            environment: envType,
            normalizeDepth: 10, //Deep for redux state
            release: appVersion,
            tracesSampleRate: 0.1, // Capture 10% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.02, // This sets the sample rate at 2%
            replaysOnErrorSampleRate: 1.0, //  The sample rate of 100% when sampling sessions where errors occur.
            beforeSend: (event, hint) => {
                const error = hint?.originalException;
                // Check if the error is from GTM
                const errorStack =
                    (hint?.originalException as Error)?.stack ?? '';
                if (
                    typeof errorStack === 'string' &&
                    errorStack.includes('/gtm.js')
                ) {
                    console.info('Prevented Sentry from reporting GTM error');
                    return null;
                }

                // Check if this is an Axios Network Error
                if (
                    error &&
                    typeof error === 'object' &&
                    'message' in error &&
                    error.message === 'Network Error' &&
                    'code' in error &&
                    (error.code === 'ERR_NETWORK' ||
                        error.code === 'ECONNABORTED')
                ) {
                    console.info(
                        'Prevented Sentry from reporting Axios Network Error',
                    );
                    return null;
                }

                // Check if it's an Axios error with a response status that we want to filter
                if (error && typeof error === 'object' && 'response' in error) {
                    const response = error.response;
                    // Make sure response is an object with a status property
                    if (
                        response &&
                        typeof response === 'object' &&
                        'status' in response
                    ) {
                        const statusCode = response.status as number | string;

                        // Filter out 401, 422, and 429 status codes
                        if (
                            unauthorisedStatusCodes.includes(statusCode) ||
                            validationErrorStatusCodes.includes(statusCode) ||
                            tooManyRequestsStatusCodes.includes(statusCode)
                        ) {
                            // Return null to prevent the event from being sent to Sentry
                            console.info(
                                `Prevented Sentry from reporting status code ${statusCode}`,
                            );
                            return null;
                        }
                    }
                }

                // Return the event to allow it to be sent to Sentry
                return event;
            },
        });
    },
};

export default SentryService;
