import structuredClone from 'core-js-pure/actual/structured-clone';
import { at } from 'core-js/features/array/at';

if (typeof window.structuredClone === 'undefined') {
    window.structuredClone = structuredClone;
}

if (typeof Array.prototype.at === 'undefined') {
    /**
     * Some browsers do not have support for the replaceAll function.
     * This will add support for the missing function
     */
    // eslint-disable-next-line no-extend-native
    Array.prototype.at = at;
}

if (typeof String.prototype.replaceAll === 'undefined') {
    /**
     * Some browsers do not have support for the replaceAll function.
     * This will add support for the missing function
     */
    // eslint-disable-next-line no-extend-native
    String.prototype.replaceAll = function (match: string, replace) {
        return this.replace(new RegExp(match, 'g'), () => replace);
    };
}

if (typeof Promise.withResolvers === 'undefined') {
    Promise.withResolvers = <T>() => {
        let resolve: (value: T | PromiseLike<T>) => void;
        let reject: (reason?: unknown) => void;
        const promise = new Promise<T>((res, rej) => {
            resolve = res;
            reject = rej;
        });
        return { promise, resolve: resolve!, reject: reject! };
    };
}
