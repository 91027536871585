import * as Sentry from '@sentry/react';
import { ProjectEndpoints } from '_constants/projectConstants';
import FetchWithKeepAlive from '_services/Api/_utils/FetchWithKeepAlive';
import LocalforageIphoneFix from '_services/LocalStorage/LocalforageIphoneFix';
import { ProjectUpdateDataShape } from '_types/Project';
import { AxiosResponse } from 'axios';
import ProjectUpdateHelper from 'Hooks/Project/ProjectUpdateHelper';

export const projectDataKey = 'project_data';

type LocalForageProjectDataShape = {
    [projectId: number]: ProjectUpdateDataShape;
} | null;

const LocalForageProjectData = {
    /**
     * Add or update Project data in IndexDB through localforage
     */
    addOrUpdate: (projectData: ProjectUpdateDataShape): void => {
        LocalForageProjectData.getList().then(
            (project: LocalForageProjectDataShape) => {
                if (!project) project = {};
                const projectId = projectData?.id;

                if (projectId) {
                    project[projectId] = {
                        ...(project[projectId] ?? {}),
                        ...projectData,
                    };

                    LocalforageIphoneFix.setItem(projectDataKey, project);
                }
            },
        );
    },
    getById: async (id: number): Promise<ProjectUpdateDataShape | null> => {
        const projectList = await LocalForageProjectData.getList();

        return (projectList && projectList[id]) ?? null;
    },

    /**
     * Get List of project data present in IndexDB
     */
    getList: (): Promise<LocalForageProjectDataShape> => {
        return LocalforageIphoneFix.getItem(
            projectDataKey,
        ) as Promise<LocalForageProjectDataShape>;
    },

    /**
     * Delete Project data from IndexDB by id through localforage
     */
    delete: (projectId: number): void => {
        LocalForageProjectData.getList().then(
            (projects: LocalForageProjectDataShape) => {
                if (projects && projects[projectId]) {
                    delete projects[projectId];

                    LocalforageIphoneFix.setItem(projectDataKey, projects);
                }
            },
        );
    },

    /**
     * clear all after Sync with server
     */
    saveAndClearAll: (): void => {
        LocalForageProjectData.getList().then(
            async (projects: LocalForageProjectDataShape) => {
                if (projects) {
                    const url = ProjectEndpoints.update;
                    const projectIds = Object.keys(projects);

                    projectIds.forEach(async (projectId) => {
                        const project = projects[projectId] ?? null;
                        const id = Number(projectId);
                        if (!project) {
                            LocalForageProjectData.delete(id);
                            return;
                        }
                        let r;
                        try {
                            r = await FetchWithKeepAlive({
                                path: url,
                                method: 'POST',
                                body: JSON.stringify(project),
                            });

                            const data = await r.json();
                            const result = {
                                status: r.status,
                                data,
                                statusText: r.statusText,
                                headers: {},
                                config: {
                                    url: r.url,
                                },
                            };

                            /**
                             * Updating the state of project in Redux
                             */
                            ProjectUpdateHelper.onSuccess(
                                result as AxiosResponse,
                                project,
                            );
                        } catch (err) {
                            console.log(
                                'Error when saving and clearing in Product update in LocalForageProductData',
                                err,
                            );
                            Sentry.captureException(err, {
                                level: 'error',
                                extra: {
                                    projectId,
                                    response: r,
                                },
                            });
                        }
                    });
                }
            },
        );
    },
};

export default LocalForageProjectData;
