/**
 * This utility provides functions to test API connectivity and diagnose issues
 */
import { backendApi } from '_config';

type ApiTestResult = {
    success: boolean;
    message: string;
    details: {
        status?: number;
        statusText?: string;
        url?: string;
        error?: unknown;
        backendApi?: string;
        windowOrigin?: string;
        currentUrl?: string;
    };
};

type DnsTestResult = {
    resolved: boolean;
    message: string;
    details: {
        hostname: string;
        error?: unknown;
    };
};

/**
 * Tests connectivity to the backend API
 * @returns Promise<ApiTestResult>
 */
export const testApiConnection = async (): Promise<ApiTestResult> => {
    try {
        // Create a simple fetch request to the server
        const controller = new AbortController();
        // Set a timeout
        const timeoutId = setTimeout(() => controller.abort(), 5000);

        const response = await fetch(`${backendApi}health-check`, {
            method: 'GET',
            credentials: 'include',
            signal: controller.signal,
        });

        clearTimeout(timeoutId);

        if (response.ok) {
            return {
                success: true,
                message: 'Successfully connected to API server',
                details: {
                    status: response.status,
                    statusText: response.statusText,
                    url: response.url,
                },
            };
        } else {
            return {
                success: false,
                message: `API server responded with error: ${response.status} ${response.statusText}`,
                details: {
                    status: response.status,
                    statusText: response.statusText,
                    url: response.url,
                },
            };
        }
    } catch (error) {
        return {
            success: false,
            message:
                error instanceof Error
                    ? `Failed to connect to API server: ${error.message}`
                    : 'Failed to connect to API server',
            details: {
                error,
                backendApi,
                windowOrigin: window.location.origin,
                currentUrl: window.location.href,
            },
        };
    }
};

/**
 * Tests DNS resolution for a hostname
 * @param hostname The hostname to test
 * @returns Promise<DnsTestResult>
 */
export const testDnsResolution = async (
    hostname: string,
): Promise<DnsTestResult> => {
    try {
        // Simple HEAD request to check if hostname resolves
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 5000);

        await fetch(`https://${hostname}`, {
            method: 'HEAD',
            mode: 'no-cors', // This is important for cross-origin requests
            signal: controller.signal,
        });

        clearTimeout(timeoutId);

        return {
            resolved: true,
            message: `Successfully resolved hostname: ${hostname}`,
            details: { hostname },
        };
    } catch (error) {
        return {
            resolved: false,
            message:
                error instanceof Error
                    ? `Failed to resolve hostname ${hostname}: ${error.message}`
                    : `Failed to resolve hostname ${hostname}`,
            details: { hostname, error },
        };
    }
};

/**
 * Run all connectivity tests and log results
 */
export const runApiDiagnostics = async (): Promise<void> => {
    console.log('--- API CONNECTION DIAGNOSTICS ---');

    // Extract hostname from backendApi
    const backendUrl = new URL(backendApi);
    const hostname = backendUrl.hostname;

    console.log('Testing DNS resolution...');
    const dnsResult = await testDnsResolution(hostname);
    console.log('DNS test result:', dnsResult);

    console.log('Testing API connectivity...');
    const apiResult = await testApiConnection();
    console.log('API test result:', apiResult);

    console.log('--- END DIAGNOSTICS ---');
};
