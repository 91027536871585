import { AxiosResponse } from 'axios';
import Api from '_services/Api';
import {
    ProjectCreateDataShape,
    ProjectShape,
    ProjectUpdateDataShape,
} from '_types/Project';
import ApiWithFileUpload from '../WithFileUpload';

const path = 'project/';

const ProjectApi = {
    async add(
        data: ProjectCreateDataShape,
    ): Promise<AxiosResponse<ProjectShape>> {
        const route = path + 'add';

        return Api.post(route, data);
    },

    async duplicate(data: ProjectShape): Promise<AxiosResponse<ProjectShape>> {
        const route = path + 'duplicate';

        return Api.post(route, data);
    },

    async update(
        data: ProjectUpdateDataShape | FormData,
    ): Promise<AxiosResponse<ProjectShape>> {
        const route = path + 'update';
        if (data instanceof FormData) {
            return ApiWithFileUpload.post(route, data);
        }
        return Api.post(route, data);
    },

    async updateSortOrder(
        data: ProjectUpdateDataShape[],
    ): Promise<AxiosResponse<ProjectUpdateDataShape[]>> {
        const route = path + 'update-sort-order';
        return Api.post(route, data);
    },

    async list(): Promise<AxiosResponse<ProjectShape[]>> {
        const route = path + 'list';

        return Api.get(route);
    },

    async delete(
        id: number,
        must_return_yarn_stock = false,
    ): Promise<AxiosResponse<void>> {
        const route = path + 'delete';
        const query = must_return_yarn_stock
            ? `?id=${id}&must_return_yarn_stock=${must_return_yarn_stock}`
            : `?id=${id}`;
        return Api.delete(route + query);
    },
};
export default ProjectApi;
