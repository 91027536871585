import { AxiosResponse } from 'axios';
import Api from '../index';
import { InterestShape } from '_services/Redux/Interest/type';

const InterestApi = {
    async getUserInterest(): Promise<AxiosResponse<InterestShape[]>> {
        return Api.get(`interests/list`);
    },
    async getAllInterest(): Promise<AxiosResponse<InterestShape[]>> {
        return Api.get(`interests/list-all`);
    },
};

export default InterestApi;
